<template>
  <div v-if="getAdmins">
    <div class="columns">
      <div class="column">
        <b-field label="العنوان">
          <b-input v-model="title" placeholder="عنوان الموقع"></b-input>
        </b-field>
        <b-field label="الموقع">
          <b-input v-model="url" placeholder="URL" type="url" dir="ltr"></b-input>
        </b-field>
        <b-field label="الوصف">
          <b-input v-model="description" type="textarea" minlength="10" maxlength="250" placeholder="وصف الموقع..."></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="عدد المواضيع لكل صفحة">
          <b-input v-model="pagination" placeholder="عدد المواضيع لكل صفحة" type="number" min="3" max="50"></b-input>
        </b-field>
      </div>
    </div>
    <b-field>
      <button class="button is-primary" @click.prevent="submitSettings">حفظ</button>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      id: null,
      title: null,
      description: null,
      url: null,
      pagination: 3
    }
  },
  created () {
    this.$store.dispatch('prepAdmins')
    this.getSettings()
  },
  methods: {
    getSettings: function () {
      var params = {
        appid: this.$store.state.config.XbAppID,
        type: 'data',
        request: 'km_settings',
        record: 'settings'
      }
      this.XbortGetRequest('', params, result => {
        if (result.result) {
          if (result.data[0].settings) {
            this.id = result.data[0]._id
            if (result.data[0].settings.title) {
              this.title = result.data[0].settings.title
            }
            if (result.data[0].settings.description) {
              this.description = result.data[0].settings.description
            }
            if (result.data[0].settings.url) {
              this.url = result.data[0].settings.url
            }
            if (result.data[0].settings.pagination) {
              this.pagination = result.data[0].settings.pagination
            }
          } else {
            this.errorMessage('No data was retrieved!')
          }
        } else {
          this.errorMessage(result.data)
        }
      })
    },
    submitSettings: function () {
      if (this.id !== null) {
        let settingsData = {
          settings: {}
        }
        if (this.title !== null) {
          settingsData.settings.title = this.title
        }
        if (this.url !== null) {
          settingsData.settings.url = this.url
        }
        if (this.description !== null) {
          settingsData.settings.description = this.description
        }
        if (this.pagination > 3) {
          settingsData.settings.pagination = this.pagination
        } else {
          settingsData.settings.pagination = 3
        }
        let params = {
          appid: this.$store.state.config.XbAppID,
          type: 'data',
          request: 'km_settings',
          id: this.id,
          data: JSON.stringify(settingsData)
        }
        this.XbortRequest('', 'put', params, result => {
          if (result.result) {
            this.successSave()
            this.getSettings()
          } else {
            this.errorMessage(result.data)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAdmins'
    ])
  }
}
</script>
